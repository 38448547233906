<template>
    <DefaultLayout>
        <DefaultHeader :showHeader=true :isApprover="this.isApprover" :language="this.lang" />
        <div id="content" class="container-fluid">
            <TabStrip :selected="selectedTab" @select="onSelectTab" :tabs="tabs">
                <template v-slot:MainView>
                    <div class="row">
                        <div class="control-group">
                            <label class="">Warehouse&nbsp;&nbsp;</label>
                            <dropdownlist :style="{ width: '230px' }" :data-items="warehouses" :value="selectedWarehouse"
                                          text-field="branchName" value-field="branchName"
                                          @change="onChangeWarehouse">
                            </dropdownlist>
                            <VueDatePicker v-model="dateRange" range :preset-dates="presetDates" :max-date="today" :format="format" class="dateRange" @update:model-value="onChangeDateRange">
                                <template #preset-date-range-button="{ label, value, presetDate }">
                                    <span role="button"
                                          :tabindex="0"
                                          @click="presetDate(value)"
                                          @keyup.enter.prevent="presetDate(value)"
                                          @keyup.space.prevent="presetDate(value)">
                                        {{ label }}
                                    </span>
                                </template>
                            </VueDatePicker>
                            <span class="custom-padding">
                                <KButton :theme-color="'primary'" @click="exportToExcel">Export</KButton>                               
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <Grid ref="grid" :columns="columns" :style="{height: '640px', fontSize: '12px'}"
                              :data-items="entries" :edit-field="'inEdit'" :sortable="true" :sort="sort"
                              :pageable="pageable" :take="take" :skip="skip" :total="total"
                              :loader="loader" >
                            <template v-slot:commandTemplate="{ props }">
                                <command :data-item="props.dataItem" @edit="editEntry" />
                            </template>
                            <!--<template v-slot:editEntry="{props}">
            <a href="http://msn.com"  @click="onClickEdit"></a>
        </template>-->
                            <!--<grid-toolbar>
            <kbutton title="Add new" :theme-color="'primary'" @click="insert">Add new</kbutton>
            <kbutton v-if="hasItemsInEdit" title="Cancel current changes" @click="cancelChanges">Cancel current changes</kbutton>
        </grid-toolbar>-->
                            <grid-norecords> There is no data available custom </grid-norecords>
                        </Grid>
                        <dialog-container v-if="entryInEdit" :data-item="entryInEdit"  @save="saveEntry" @reject="rejectEntry" @cancel="cancelEntry">
                        </dialog-container>
                    </div>
                </template>
            </TabStrip>
        </div>
    </DefaultLayout>
</template>
<style>
    .k-dialog {
        padding: 20px;
        min-width: 600px;
    }
    .k-dialog .k-form {
        font-size: 14px;
    }
    .k-input-value-text.k-input {
        font-size: 14px;
    }
    input.k-input {
        font-size: 14px;
        padding-bottom: 1px;
    }
    textarea.k-input-inner {
        font-size: 14px;
    }
    .k-form-horizontal .k-form-field {
        padding-bottom: 2px;
    }
    .k-form-horizontal .k-form-field > .k-form-label {
        width: 110px;
    }
    .k-form .k-form-field-wrap {
    }
    .k-grid {
        margin: 15px;
    }
    .k-grid .k-button {
        height: 35px;
        margin: 4px 10px;        
    }
    .k-grid .k-table-th, .k-grid td, .k-grid .k-table-td {
        font-size: 14px;
    }

    .dp__clear_icon {
        display: none;
    }

    .k-button-solid-primary {
        font-size: 14px;
        background-color: green;
        color: white;
        border-color: green;
    }

    .dp__input, .dp__btn, .dp__cell_inner, .dp__calendar_header_item {
        font-size: 14px;
    }

    .dp__selection_preview {
        font-size: 16px;
    }

    .dp__action_button {
        font-size: 14px;
    }

    .dp--preset-range {
        color: blue;
    }

    .control-group {
        display: flex;
        align-items: flex-start;
        margin: 15px;
        font-size: 14px;
    }

    .dateRange {
        width: 260px;
        padding-left: 10px;
    }

    .k-input-value-text {
        font-size: 14px !important;
    }

    .k-list {
        font-size: 14px;
    }
    .k-tabstrip-items-wrapper .k-item {
        color: blue !important;
    }
    .k-tabstrip-items-wrapper .k-active {
        color: black !important;
    }
    .k-tabstrip-items .k-link {
            font-size: 14px;
        }
    .custom-padding {
        padding-left: 15px;
    }
    .k-button {
        line-height: 22px;
    }
    .k-checkbox {

    }
    .k-checkbox-md {
        width: var(--kendo-spacing-6, 1rem);
        height: var(--kendo-spacing-6, 1rem);
    }
    .k-checkbox-lg {
        width: var(--kendo-spacing-8, 1rem);
        height: var(--kendo-spacing-8, 1rem);
    }
    .k-checkbox:checked {
        border-color: green;
        background-color: green;

    }

</style>
<script>
    import { ref } from 'vue'
    import '@progress/kendo-theme-default/dist/all.css';
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { Grid, GridNoRecords } from '@progress/kendo-vue-grid';
   /* import { saveExcel } from '@progress/kendo-vue-excel-export';*/
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Button as KButton } from "@progress/kendo-vue-buttons";
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import { addDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfYear, startOfYear, subMonths, subYears } from 'date-fns';
    import SiteFocusCommandCell from './SiteFocusCommandCell';
    import SiteFocusDialog from './SiteFocusDialog';
    import DefaultHeader from '../layouts/DefaultHeader.vue';
    import DefaultLayout from '../layouts/DefaultLayout.vue';
    import '@fortawesome/fontawesome-free/css/all.min.css';
    import ExcelJS from 'exceljs';
    import { saveAs } from 'file-saver';
    import { store } from '../stores/store.js';

    export default {
        name: "SiteFocusView",
        components: {
            DefaultHeader,
            DefaultLayout,
            Grid: Grid,
            'grid-norecords': GridNoRecords,
            'dropdownlist': DropDownList,
            command: SiteFocusCommandCell,
            'dialog-container': SiteFocusDialog,
            VueDatePicker,
            TabStrip,
            KButton,
            
        },
        setup: function () {

            const dateRange = ref([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()]);

            const format = 'MM-dd-yyyy'; // Adjust the format as needed

            const presetDates = ref([
                { label: 'Today', value: [new Date(), new Date()] },
                { label: 'Yesterday', value: [addDays(new Date(), -1), addDays(new Date(), -1)] },
                { label: 'Last 7 Days', value: [addDays(new Date(), -6), new Date()] },
                { label: 'This Week', value: [startOfWeek(new Date()), new Date()] },
                { label: 'Last Week', value: [startOfWeek(addDays(new Date(), -7)), endOfWeek(addDays(new Date(), -7))] },
                { label: 'Last 30 Days', value: [addDays(new Date(), -29), new Date()] },
                { label: 'This month', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
                { label: 'Last month', value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))], },
                { label: 'This year', value: [startOfYear(new Date()), endOfYear(new Date())] },
                { label: 'Last year', value: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))] },
                { label: '3 Months', value: [startOfMonth(subMonths(new Date(), 3)), new Date()] },
                { label: '6 Months', value: [startOfMonth(subMonths(new Date(), 6)), new Date()] },
                { label: '12 Months', value: [startOfMonth(subMonths(new Date(), 12)), new Date()] },
                //{ label: 'Today (Slot)', value: [new Date(), new Date()],slot: 'preset-date-range-button'},

            ]);
            return {
                dateRange,
                presetDates,
                format,
            }
        },

        data: function () {
            return {         
                today: new Date(),
                isApprover: false,
                //init: { method: 'POST', accept: 'application/json', headers: {} },
                fromDate: new Date((new Date).toLocaleDateString()),
                toDate: new Date((new Date).toLocaleDateString()),
                filter: null,
                sort: null,
                pageable: true,
                entries: [],
                skip: 0,
                take: 10,
                total: 0,
                updatedData: [],
                editID: null,
                entryInEdit: undefined,
                columns: [
                    { cell: 'commandTemplate', filterable: false, width: '75px' },
                    { field: 'employeeNumber', title: 'Emp #', width: '75px' },
                    { field: 'employeeFirstName', title: 'First Name', width: '110px' },
                    { field: 'employeeLastName', title: 'Last Name', width: '110px' },
                    { field: 'reason', title: 'Category', width: '230px' },
                    { field: 'supervisor', title: 'Supervisor', width: '120px' },
                    { field: 'description', title: 'Description' },
                    { field: 'correctiveAction', title: 'Corrective Action'},
                    { field: 'dateSubmitted1', title: 'Date', width: '90px' },
                    { field: 'branchName', title: 'Whs', width: '60px' },
                    { field: 'nearMiss', title: 'Near Miss', width: '100px' },
                    { field: 'nearMissType', title: 'Near Miss Type' },
                    { field: 'id', editable: false, hidden: true, title: 'id' },
                ],
                loader: false,
                labels: {
                    headerText: 'View Entry'
                },
                selectedTab: 0,
                tabs: [{ title: 'Approved', content: 'MainView' }, { 'title': 'Rejected', content: 'MainView' }],
                selectedWarehouse: { branchName: 'All Warehouses' },
                warehouses: [],
                wayneLogoUrl: require('@/assets/img/logos/WayneLogo.png'),
                //items: [
                //    { employeeFirstName: 'John', employeeLastName: 'Doe', company: 'Company A', whsCode: 'WH1', employeeNumber: 123456, points: 10, reason: 'Good Work', description: 'Completed project successfully' },
                //    { employeeFirstName: 'Jane', employeeLastName: 'Smith', company: 'Company B', whsCode: 'WH2', employeeNumber: 654321, points: 20, reason: 'Excellent Performance', description: 'Exceeded targets' }
                //],

                //value: '',
            };
        },
        computed: {
            dataState() {
                return {
                    sort: this.sort,
                    skip: this.skip,
                    take: this.take,
                };
            },
        },
        created: function () {
            this.getWarehouses();
            this.getMainData(true);
        },

        mounted() {
            this.isApprover = store.isApprover;
        },
        methods: {
            editEntry: function (dataItem) {

                //console.log('inside edit entry');
                //console.log(dataItem);

                this.entryInEdit = dataItem;
            },
            saveEntry: function () {

                const requestOptions = {
                    method: 'POST', accept: 'application/json', headers: {
                        Accept: 'application/json', 'Content-Type': 'application/json'
                    }, body: JSON.stringify({ id: this.entryInEdit.id, isApproved: this.entryInEdit.isApproved })
                };

                fetch(`/sharpEntries/updateEntry`, requestOptions)
                    .then(() => {

                        let index = this.entries.findIndex(x => x.id == this.entryInEdit.id);

                        //console.log('curren tab => ' + this.selectedTab);
                        //console.log('is approved => ' + this.entryInEdit.isApproved);


                        if ((this.selectedTab === 0 && !this.entryInEdit.isApproved)
                            || (this.selectedTab === 1 && this.entryInEdit.isApproved)) {

                            console.log('approved change');

                            this.entries.splice(index, 1);
                        }
                        else {
                            this.entries[index] = this.entryInEdit;
                        }

                        this.entryInEdit = undefined;

                    });
            },
            rejectEntry: function () {

                console.log('reject entry');

                const requestOptions = {
                    method: 'POST', accept: 'application/json', headers: {
                        Accept: 'application/json', 'Content-Type': 'application/json'
                    }, body: JSON.stringify({ id: this.entryInEdit.id, isApproved: false })
                };

                fetch(`/sharpEntries/updateEntry`, requestOptions)
                    .then(() => {

                        let index = this.entries.findIndex(x => x.id == this.entryInEdit.id);
                        this.entries.splice(index, 1);
                        this.entryInEdit = undefined;
                    });
            },
            cancelEntry: function () {
                this.entryInEdit = undefined;
            },
            onSelectTab: function (event) {

                this.selectedTab = event.selected;
                this.getMainData(this.selectedTab === 0);
            },
            getWarehouses: function () {
                const parent = this;

                const requestOptions = {
                    method: 'POST', accept: 'application/json', headers: {
                        Accept: 'application/json', 'Content-Type': 'application/json'
                    }
                };

                fetch(`/sharpBranch/getAllBranches`, requestOptions)
                    .then((response) => response.json())
                    .then((json) => {

                        //console.log('returned values');
                        //console.log(json);

                        parent.warehouses = json;
                        parent.warehouses.unshift({ branchName: 'All Warehouses' });

                    });
            },
            //onClickEdit: function (dataItem) {

            //    //alert('button click');

            //    //console.log('edit');
            //    //console.log(dataItem);
            //},
            //onClickReject: function (dataItem) {
            //    //console.log('reject');
            //    //console.log(dataItem);
            //},
            getMainData: function (isApproved = true) {

                const that = this;
                this.loader = true;
                const branchName = (this.selectedWarehouse.branchName === "All Warehouses") ? "" : this.selectedWarehouse.branchName;

                const requestOptions = {
                    method: 'POST', accept: 'application/json', headers: {
                        Accept: 'application/json', 'Content-Type': 'application/json'
                    }, body: JSON.stringify({ branchName: branchName, fromDate: this.dateRange[0], toDate: this.dateRange[1], isApproved: isApproved })
                }

                fetch(`/sharpEntries/getSharpEntriesByDate`, requestOptions)
                    .then((response) => response.json())
                    .then((json) => {

                        //console.log(json);

                        that.total = json['recordsTotal']
                        that.entries = json['data'];
                        this.loader = false;
                    });
            },

            onChangeWarehouse: function (event) {

                this.selectedWarehouse = event.value;
                this.getMainData(true);
            },

            onChangeDateRange: function () {
                this.getMainData(true);
            },
            //exportExcel() {
            //    saveExcel({
            //        data: this.entries,
            //        fileName: "sitefocus",
            //        columns: [
            //            { field: 'employeeNumber', title: 'Emp #', width: '80px' },
            //            { field: 'employeeFirstName', title: 'First Name', width: '120px' },
            //            { field: 'employeeLastName', title: 'Last Name', width: '120px' },
            //            { field: 'reason', title: 'What', width: '230px' },
            //            { field: 'name', title: 'Who', width: '120px' },
            //            { field: 'description', title: 'Description' },
            //            { field: 'dateSubmitted1', title: 'Date', width: '90px' },
            //            { field: 'whsCode', title: 'Whs', width: '65px' },
            //        ]
            //    });
            //},
            async exportToExcel() {

                const maxColumns = 16;
                const startingRow = 11;

                const currentDate = new Date();
                const currentMonth = currentDate.toLocaleString('default', { month: 'long' });

                // Create a new workbook and worksheet
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Detail');

                               
                worksheet.addRow([]);
                this.makeBoldFont(worksheet.addRow(['Company Name:', '', 'SIMI Holdings']));
                this.makeBoldFont(worksheet.addRow(['Submitted By:', '', 'Lisa Layfield']));
                this.makeBoldFont(worksheet.addRow(['Date Submitted:', '', this.formatDate(currentDate), 'submitted - date', `${currentMonth} Pts.`]));
                worksheet.addRow([]);
                this.makeBoldFont(worksheet.addRow(['Date Uploaded*:']));
                this.makeBoldFont(worksheet.addRow(['Uploaded By*:']));
                this.makeBoldFont(worksheet.addRow(['*Internal Use Only']));
                worksheet.addRow([]);
                worksheet.addRow([]);

                const rowHeader = worksheet.addRow(['First Name', 'Last Name', 'Company', 'Department', 'Employee ID', 'Birthday', `${currentMonth} Points`, `${currentMonth} Points Submitted`, 'Type', 'Description', 'Corrective Action', 'Reason', 'New', 'Termination Date', 'Near Miss', 'Near Miss Type']);
                rowHeader.height = 38                
                rowHeader.alignment = { vertical: 'middle' };
                rowHeader.font = { bold: true };
            

                this.applyThinBordersToRowsColumns(worksheet, 2, 4, 3, 6);
                this.applyThinBordersToRowsColumns(worksheet, 6, 7, 3, 6);
                this.applyThinBordersToRowsColumns(worksheet, 11, 11, 1, maxColumns);

                     // Add data rows
                this.entries.forEach((item) => {
                    const newRow = worksheet.addRow([
                        item.employeeFirstName,
                        item.employeeLastName,
                        item.company,
                        `${item.company} ${item.whsCode} Houston`,
                        item.employeeNumber.toString().padStart(6, '0'),
                        'N/A',
                        item.points,
                        item.points,
                        item.reason,
                        item.description,
                        item.correctiveAction,
                        currentMonth,
                        '',
                        '',
                        item.nearMiss,
                        item.nearMissType,
                    ]);
                    this.applyThinBorders(newRow);
                });

                // Load the logo image as a base64 string
                const logoBase64 = await this.loadImageBase64(this.wayneLogoUrl);

                // Add the logo image to the worksheet
                const imageId = workbook.addImage({
                    base64: logoBase64,
                    extension: 'png'
                });
                worksheet.addImage(imageId, {
                    tl: { col: 9, row: 2 },
                    ext: { width: 150, height: 50 }
                });

                worksheet.autoFilter = { from: { row: startingRow, column: 1 }, to: { row: this.entries.length + startingRow, column: maxColumns } };
                this.autoResizeColumns(worksheet);

                const pointsTotal = {};

                this.entries.forEach( (x) => {
                    const key = `${x.employeeNumber.toString().padStart(6, '0')}`;

                    if (pointsTotal[key]) {
                        pointsTotal[key].points += x.points;
                        pointsTotal[key].pointsSubmitted += x.points;
                    }
                    else {
                        pointsTotal[key] = {
                            firstName: x.employeeFirstName, lastName: x.employeeLastName, company: x.company, department: `${x.company} ${x.whsCode} Houston`,
                            employeeNumber: `${x.employeeNumber.toString().padStart(6, '0')}`, birthday: 'N/A', points: x.points, pointsSubmitted: x.points
                        };
                    }
                });

                const sheehtTotal = workbook.addWorksheet('Total');
                const rowHeader2 = sheehtTotal.addRow(['First Name', 'Last Name', 'Company', 'Department', 'Employee ID', 'Birthday', `${currentMonth} Points`, `${currentMonth} Points Submitted`]);
                rowHeader2.height = 38
                rowHeader2.alignment = { vertical: 'middle' };
                rowHeader2.font = { bold: true };

                this.applyThinBordersToRowsColumns(sheehtTotal, 1, 1, 1, 8);

                for (const key in pointsTotal) {

                    const { firstName, lastName, company, department, employeeNumber, birthday, points, pointsSubmitted } = pointsTotal[key];

                    const newRow = sheehtTotal.addRow([
                        firstName,
                        lastName,
                        company,
                        department,
                        employeeNumber,
                        birthday,
                        points,
                        pointsSubmitted,
                    ]);

                    this.applyThinBorders(newRow);
                }
                this.autoResizeColumns(sheehtTotal);

                sheehtTotal.autoFilter = { from: { row: 1, column: 1 }, to: { row: this.entries.length + 1, column: 8 } };

         
                // Set the first sheet as active
                workbook.views = [{ state: 'frozen', xSplit: 0, ySplit: 0, topLeftCell: 'A1', activeCell: 'A1' }];
                workbook.views[0].activeTab = 0; // Index of the first sheet
               
                // Export the workbook to a file
                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/octet-stream' });
                saveAs(blob, `Points_Submission_${this.formatDate(currentDate, 'dd MMMM yyyy')}.xlsx`);

            },
            makeBoldFont(row) {
                row.font = { bold: true };
            },
            autoResizeColumns(worksheet) {                
                worksheet.columns.forEach(column => {
                    let maxLength = 0;
                    column.eachCell({ includeEmpty: true }, cell => {
                        const columnLength = cell.value ? cell.value.toString().length : 10;
                        if (columnLength > maxLength) {
                            maxLength = columnLength;
                        }
                    });
                    column.width = maxLength < 10 ? 10 : maxLength;
                });
            },
            applyThinBorders(row) {
                row.eachCell(cell => {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
            },
            applyThinBordersToRowsColumns(worksheet, startRow, endRow, startCol, endCol) {
                for (let row = startRow; row <= endRow; row++) {
                    for (let col = startCol; col <= endCol; col++) {
                        worksheet.getCell(row, col).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    }
                }
            },
            async loadImageBase64(url) {
                const response = await fetch(url);
                const blob = await response.blob();
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            },
            //applyHeaderStyles(worksheet) {
            //    const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
            //    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
            //        const cell = worksheet[XLSX.utils.encode_cell({ r: headerRange.s.r, c: C })];
            //        if (!cell.s) cell.s = {};
            //        cell.s.font = { bold: true, color: { rgb: "FFFFFF" } };
            //        cell.s.fill = { fgColor: { rgb: "4F81BD" } };
            //        cell.s.alignment = { horizontal: 'center', vertical: 'center' };
            //        cell.s.border = {
            //            top: { style: 'thin' },
            //            bottom: { style: 'thin' },
            //            left: { style: 'thin' },
            //            right: { style: 'thin' }
            //        };
            //    }
            //},
            formatDate(date, format = 'short') {
                console.log(format);

                const options = { year: 'numeric', month: 'short', day: 'numeric' };
                return date.toLocaleDateString(undefined, options);
            },
            getCurrentMonth() {
                const date = new Date();
                return date.toLocaleString('default', { month: 'long' });
            }
        }
    }
</script>





<!--<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 center-block" id="step2">
        <div class="well no-padding">
            <form id="sharpForm" class="smart-form client-form" novalidate="novalidate">
                <header>
                    {{ labels.headerText }}
                    {{ this.fromDate.toLocaleString() }} - {{ this.toDate.toLocaleString() }}
                </header>
            </form>
        </div>
    </div>
</div>-->
